<!--
 * @Author: DY
 * @Date: 2020-09-01 16:05:30
 * @LastEditors: 任赛赛 5595670+ren_sai_sai_sai@user.noreply.gitee.com
 * @LastEditTime: 2023-01-12 11:45:39
 * @Describe:
-->
<template>
    <div
        class="contractLifeCycle"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
        <header class="lifeCycleHeader">
            <div class="title">
                合同生命周期
            </div>
        </header>
        <div class="lifeCycleContent">
            <div class="box_t">
                <div class="box_t_l el_left">
                    <div class="basicEngineeringInfo boxBorder">
                        <div class="engineeringInfo el_left boxShadow">
                            <h4 class="header">
                                合同信息
                            </h4>
                            <ul>
                                <li>
                                    <p>合同编号：{{ basicContractInfo.contract_number }}</p>
                                </li>
                                <li>
                                    <p>客户名称：{{ basicContractInfo.client_name }}</p>
                                </li>
                                <li>
                                    <p>工程名称：{{ basicContractInfo.engineering_name }}</p>
                                </li>
                                <li>
                                    <p style="color: #C4FE72; cursor: pointer;" @click="viewMonomer(basicContractInfo.engineering_id)">
                                        单体数量：{{ basicContractInfo.monomer_count }}个
                                        <i class="iconfont iconshuangyoujiantou-"></i>
                                    </p>
                                    <p>工程类型：{{ basicContractInfo.engineering_type_name }}</p>
                                </li>
                                <li>
                                    <p>签约时间：{{ basicContractInfo.take_effect_time }}</p>
                                    <p>预计开工时间：{{ basicContractInfo.predicted_startwork_time }}</p>
                                </li>
                                <li>
                                    <p>客户联系人：{{ basicContractInfo.client_contact_name }}</p>
                                    <p>电话：{{ basicContractInfo.client_contact_phone }}</p>
                                </li>
                                <li>
                                    <p>业务员：{{ basicContractInfo.salesman_name }}</p>
                                    <p>电话：{{ basicContractInfo.salesman_phone }}</p>
                                </li>
                            </ul>
                            <button @click="contractFile(basicContractInfo.contract_number)">
                                合同档案<i class="iconfont iconshuangyoujiantou-"></i>
                            </button>
                            <button @click="dataGovernance(basicContractInfo.contract_number)">
                                管控数据<i class="iconfont iconshuangyoujiantou-"></i>
                            </button>
                        </div>
                        <div class="el_right">
                            <div class="projectLocation boxShadow">
                                <h4 class="header">
                                    工程信息
                                </h4>
                                <div class="chart" id="ontractStationChart"></div>
                                <div class="map">
                                    <miniMap :essential-info="basicContractInfo"></miniMap>
                                </div>
                            </div>
                            <div class="imageProgress boxShadow">
                                <h4 class="header">
                                    <span class="el_left">形象进度</span>
                                    <button class="el_right" @click="projectProgress">
                                        更多
                                        <i class="iconfont iconshuangyoujiantou-"></i>
                                    </button>
                                </h4>
                                <ul :class="{'noData': enclosureData.length<1}">
                                    <template>
                                        <li>
                                            <iframe src="" frameborder="0"></iframe>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="projectProgress boxBorder">
                        <div class="timeAxis boxShadow">
                            <div class="title">
                                <h4 class="header el_left">
                                    时间轴
                                </h4>
                                <template v-for="(item,index) in timeAxisOption">
                                    <button :key="index" :class="{'active':timeAxiSelect == index}" @click="timeAxisFilteringData(item.code, index)">
                                        {{ item.name }}
                                    </button>
                                </template>
                            </div>
                            <ul>
                                <li>
                                    <span>订单个数(个)：</span>
                                    <strong>{{ timelineData.order_count }}</strong>
                                </li>
                                <li>
                                    <span>发货车次(次)：</span>
                                    <strong>{{ timelineData.delivery_count }}</strong>
                                </li>
                                <li>
                                    <span>销售方量(m³)：</span>
                                    <strong>{{ timelineData.sale_quantity }}</strong>
                                </li>
                                <li>
                                    <span>发货方量(m³)：</span>
                                    <strong>{{ timelineData.delivery_quantity }}</strong>
                                </li>
                                <li>
                                    <span>签收方量(m³)：</span>
                                    <strong>{{ timelineData.sign_quantity }}</strong>
                                </li>
                                <li>
                                    <span>退砼方量(m³)：</span>
                                    <strong>{{ timelineData.back_quantity }}</strong>
                                </li>
                                <li>
                                    <span>签收率：</span>
                                    <strong>{{ timelineData.sign_percent }}</strong>
                                </li>
                            </ul>
                        </div>
                        <div class="charts">
                            <div class="materialRequestTime boxShadow">
                                <h4 class="header">
                                    要料时间
                                </h4>
                                <div class="chart" id="materialRequestTime_contrant"></div>
                            </div>
                            <div class="constructionProgress boxShadow">
                                <h4 class="header">
                                    产品类型
                                </h4>
                                <div class="chart" id="productYypeChart"></div>
                            </div>
                        </div>
                        <div class="charts amountMoney">
                            <div class="salesAmount boxShadow">
                                <h4 class="header">
                                    销售金额
                                </h4>
                                <div class="chart" id="salesAmountChart"></div>
                            </div>
                            <div class="creditAmount boxShadow">
                                <h4 class="header">
                                    授信金额
                                </h4>
                                <ul>
                                    <li>
                                        <div class="chart" id="creditSmountChart"></div>
                                        <div class="totalAmount">
                                            <h5>授信金额(万元)</h5>
                                            <span>{{ volumeData.s_credit_money }}</span>
                                        </div>
                                        <div class="amountUsed">
                                            <h5>已用金额(万元)</h5>
                                            <span>{{ volumeData.s_credit_consume_money }}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="chart" id="excessAmountChart"></div>
                                        <div class="totalAmount">
                                            <h5>超限申请额度(万元)</h5>
                                            <span>{{ volumeData.s_transfinite_apply_total_money }}</span>
                                        </div>
                                        <div class="amountUsed">
                                            <h5>已用超限额度(万元)</h5>
                                            <span>{{ volumeData.s_transfinite_use_total_money }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box_t_r el_right boxBorder">
                    <div class="amount boxShadow">
                        <div class="title">
                            <i></i>
                            <p>
                                <strong>已供方量(方)：</strong>
                                <span>{{ volumeData.l_used_capacity }}</span>
                            </p>
                        </div>
                        <div class="chart" id="amountChart_contract"></div>
                        <ul>
                            <li>
                                <h5>已供方量(方)</h5>
                                <span>{{ volumeData.l_used_capacity }}</span>
                            </li>
                            <li>
                                <h5>预计方量(方)</h5>
                                <span>{{ volumeData.l_projected_capacity }}</span>
                            </li>
                            <li>
                                <h5>预计剩余(方)</h5>
                                <span>{{ volumeData.l_projected_surplus_capacity }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="price boxShadow">
                        <div class="title">
                            <i></i>
                            <p>
                                <strong>C30当前价：</strong>
                                <span>{{ volumeData.j_c30_price }}</span>
                            </p>
                        </div>
                        <ul>
                            <li>
                                <h5 class="iconfont iconqian">
                                    签订价格(元/方)
                                </h5>
                                <p>{{ volumeData.j_contract_price }}</p>
                            </li>
                            <li :class="{'low': volumeData.j_compared_enterprise_price < 0}">
                                <h5 class="iconfont iconqian">
                                    较企业均价
                                </h5>
                                <p>
                                    {{ volumeData.j_compared_enterprise_price }}
                                    <span>%</span>
                                    <i class="iconfont iconjiang-t"></i>
                                    <i class="iconfont iconjiang"></i>
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div class="paragraph boxShadow">
                        <div class="title">
                            <i></i>
                            <p>
                                <strong>应收账款余额：</strong>
                                <span>{{ volumeData.k_accounts_receivable_balance }}</span>
                            </p>
                        </div>
                        <ul>
                            <li>
                                <div>
                                    <h5>累计应收(元)</h5>
                                    <span>{{ volumeData.k_sales_amount }}</span>
                                </div>
                                <div>
                                    <h5>累计实收(元)</h5>
                                    <span>{{ volumeData.k_aggregate_official_receipt }}</span>
                                </div>
                                <div>
                                    <h5>累计回款率</h5>
                                    <span>{{ volumeData.k_cumulative_return_rate }}</span>
                                </div>
                            </li>
                            <li>
                                <div class="receivable">
                                    <h5>累计按节点应收(元)</h5>
                                    <span>{{ volumeData.k_aggregate_receivables }}</span>
                                </div>
                                <div class="arrears">
                                    <h5>逾期欠款(元)</h5>
                                    <span>{{ volumeData.k_overdue_arrears }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="consumption boxShadow">
                        <h4 class="header">
                            预警管控
                        </h4>
                        <ul :class="{'noData':controlNoticeData.length < 0}">
                            <template v-for="(item,index) in controlNoticeData">
                                <li
                                    :key="index"
                                    :class="{'earlyWarning': item.control_type == '1','control': item.control_type == '2'}"
                                    :title="item.title"
                                >
                                    <h5 v-if="item.control_type == '1'">
                                        预警通知
                                    </h5>
                                    <h5 v-if="item.control_type == '2'">
                                        管控通知
                                    </h5>
                                    <p>
                                        <span class="el_left">{{ item.control_name }}</span>
                                        <strong class="el_right" @click="controlDetails(item)">【管控数据】</strong>
                                    </p>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// 地图组件
import miniMap from '@/views/lifeCycle/miniMap_contract';

import {
    waterPolo,
    ringDiagram,
    scatterPlot,
    drawLine,
    lineColumn,
    ciYun,
} from './../../assets/js/charts';
import 'echarts-liquidfill';

export default {
    // 合同生命周期弹出层
    name: 'contract-life-cycle',
    components: {
        miniMap,
    },
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            // 当前合同编号
            contract_number: '',
            // 租户id
            zuHId: '',
            // 月天数
            monthDays: 0,
            // 时间轴选项
            timeAxisOption: [{
                name: '全部',
                code: '0',
            }, {
                name: '本月',
                code: '1',
            }, {
                name: '本周',
                code: '2',
            }],
            // 时间轴选中下标
            timeAxiSelect: 0,
            // 合同基本信息
            basicContractInfo: {},
            // 形象进度图片
            enclosureData: [],
            // 获取获取量,价,款,授信金额数据
            volumeData: {},
            // 工程单方消耗数据
            isConsumeData: false,
            // 时间轴数据
            timelineData: {},
            // 管控通知数据
            controlNoticeData: [],
        };
    },
    created() {
        this.contract_number = this.extr.code.contract_number;
        this.zuHId = this.extr.code.zuHId;
        // 月天数
        this.monthDays = this.mGetDate(this.getNowFormatDate());
        // 初始化页面
        if (this.contract_number && this.contract_number !== '') {
            this.init();
        }

    },
    mounted() {},
    updated() {},
    methods: {
        // 格式化当前日期
        getNowFormatDate(data) {
            const date = new Date(data);
            const seperator1 = '-';
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const strDate = date.getDate();
            const currentdate = year + seperator1 + month + seperator1 + strDate;
            return currentdate;
        },
        // 获取当前周一周天日期
        getWeekStr() {
            const now = new Date();
            const nowTime = now.getTime();
            const day = now.getDay();
            const oneDayTime = 24 * 60 * 60 * 1000;
            const MondayTime = new Date(nowTime - (day - 1) * oneDayTime);// 显示周一
            const SundayTime = new Date(nowTime + (7 - day) * oneDayTime);// 显示周日

            const newtime = [this.getNowFormatDate(MondayTime), this.getNowFormatDate(SundayTime)];
            return newtime;
        },
        // 获取本月第一天
        currentMonthFirst() {
            const date = new Date();
            date.setDate(1);
            return this.getNowFormatDate(date);
        },
        // 获取本月最后一天
        currentMonthLast() {
            const date = new Date();
            let currentMonth = date.getMonth();
            const nextMonth = ++currentMonth;
            const nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
            const oneDay = 1000 * 60 * 60 * 24;
            const newTime = new Date(nextMonthFirstDay - oneDay);
            return this.getNowFormatDate(newTime);
        },
        // 获取当前月天数
        mGetDate() {
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const d = new Date(year, month, 0);
            return d.getDate();
        },
        // 初始化页面
        async init() {
            this.loading = true;
            // 工程基本信息
            this.basicContractInfo = {};
            // 方量数据
            this.volumeData = {};
            // 时间轴数据
            this.timelineData = {};

            // 获取合同基本信息数据
            await this.getBasicContractInfo();
            // 获取获取量,价,款,授信金额数据
            await this.getVolumeData();
            // 获取时间轴数据
            await this.getTimelineData();
            // 拉取管控通知数据
            await this.getControlNoticeData();
            this.loading = false;
        },
        // 时间轴过滤时间段数据
        timeAxisFilteringData(data, i) {
            // 时间轴数据
            this.timelineData = {};
            let newUrl = '';
            this.timeAxiSelect = i;
            if (data === '0') {
                newUrl = `${this.contract_number}?zu_h_id=${this.zuHId}`;
            } else if (data === '1') {
                const monthFirst = this.currentMonthFirst();
                const monthLast = this.currentMonthLast();
                newUrl = `${this.contract_number}?start=${monthFirst}&end=${monthLast}&zu_h_id=${this.zuHId}`;
            } else if (data === '2') {
                const newTime = this.getWeekStr();
                newUrl = `${this.contract_number}?start=${newTime[0]}&end=${newTime[1]}&zu_h_id=${this.zuHId}`;
            }
            // 获取时间轴数据
            this.getTimelineData(newUrl);
        },
        // 获取合同基本信息数据
        getBasicContractInfo() {
            this.$axios
                .get(`/interfaceApi/sale/contract_life_cycle/contract_info/${this.contract_number}?zu_h_id=${this.zuHId}`)
                .then(res => {
                    if (res) {
                        // 工程基本信息
                        this.basicContractInfo = res;

                        const chartData = {
                            title: '供货站点：',
                            data: res.supply_site_names,
                        };
                        ciYun('ontractStationChart', chartData);

                        if (res.visual_progress_images && res.visual_progress_images !== '') {
                            const newData = res.visual_progress_images.split(',');
                            newData.forEach(item => {
                                // item = this.FILE.FILE_BASE_URL() + 'file/preview/' + item;
                                // this.enclosureData.push(item);
                                this.getFileDetailFun(item, 'enclosureData');
                            });
                        }
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        getFileDetailFun(id, key) {
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + id)
                .then(res => {
                    this[key].push(res.preview_url);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取获取量,价,款,授信金额数据
        getVolumeData() {
            this.$axios
                .get(`/interfaceApi/sale/contract_life_cycle/contract_quantity/${this.contract_number}?zu_h_id=${this.zuHId}`)
                .then(res => {
                    if (res) {
                        // 工程基本信息
                        this.volumeData = res;
                        const chartData = {
                            title: '',
                            data: [],
                        };
                        if (res.l_structure_mark && res.l_structure_mark.length > 0) {
                            chartData.title = '方量';
                            res.l_structure_mark.forEach((item, index) => {
                                chartData.data.push({
                                    name: item,
                                    value: res.l_structure_quantity[index],
                                });
                            });
                        } else {
                            chartData.title = '暂无数据';
                        }
                        ringDiagram('amountChart_contract', chartData);


                        // 授信金额
                        const creditSmountChartData = {
                            color: ['#05A9F4'],
                            data: [res.s_credit_consume_percent],
                        };
                        waterPolo('creditSmountChart', creditSmountChartData);
                        // 超限申请额度
                        const excessAmountChartData = {
                            color: ['#FFB205'],
                            data: [res.s_transfinite_use_total_percent],
                        };
                        waterPolo('excessAmountChart', excessAmountChartData);
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 获取时间轴数据
        getTimelineData(url) {
            if (!url) {
                url = `${this.contract_number}?zu_h_id=${this.zuHId}`;
            }
            this.$axios
                .get('/interfaceApi/sale/contract_life_cycle/contract_timer_shaft/' + url)
                .then(res => {
                    if (res) {
                        this.timelineData = res;
                        if (res.scatter_punchCard.length > 0) {
                            scatterPlot('materialRequestTime_contrant', res.scatter_punchCard);
                        }

                        const chartData = {
                            xdata: res.days,
                            legendData: res.strength_grade_name,
                            ydata: [],
                        };
                        const colors = [{
                            color: ['rgba(35, 210, 253, 1)', 'rgba(35, 210, 253, 0.8)', 'rgba(35, 210, 253, 0.1)'],
                        }, {
                            color: ['rgba(199, 83, 224, 1)', 'rgba(199, 83, 224, 0.8)', 'rgba(199, 83, 224, 0.1)'],
                        }, {
                            color: ['rgba(242, 227, 65, 1)', 'rgba(242, 227, 65, 0.8)', 'rgba(242, 227, 65, 0.1)'],
                        }, {
                            color: ['rgba(250, 132, 54, 1)', 'rgba(250, 132, 54, 0.8)', 'rgba(250, 132, 54, 0.1)'],
                        }, {
                            color: ['rgba(77, 175, 80, 1)', 'rgba(77, 175, 80, 0.8)', 'rgba(77, 175, 80, 0.1)'],
                        }];
                        res.strength_grade_name.forEach((item, index) => {
                            let color = [];
                            if (index > 4) {
                                color = ['#4DAF50', '#76E0A8', '#13BD63'];
                            } else {
                                color = colors[index].color;
                            }
                            if (res.strength_grade_quantity.length > 0) {
                                chartData.ydata.push({
                                    name: item,
                                    color: color,
                                    data: res.strength_grade_quantity[index],
                                },);
                            }
                        });
                        // 产品类型
                        drawLine('productYypeChart', chartData);

                        const dailyData = {
                            xAxis: {
                                data: res.days,
                            },
                            yAxis: [{
                                name: '(万元)',
                                nameTextStyle: '',
                            },
                            {
                                name: '(元/方)',
                                nameTextStyle: '',
                                show: 'false',
                            } ],
                            series: [ {
                                type: 'bar',
                                name: '砼金额',
                                barWidth: '20',
                                color: ['#01C8FE', '#076DFD'],
                                data: res.concrete_amount_money,
                            }, {
                                type: 'line',
                                name: '砼单价',
                                color: ['#EFAB54', '#FA8C47'],
                                data: res.concrete_price,
                            }],
                            grid: {
                                left: 60,
                                top: 40,
                                right: 40,
                                bottom: 20,
                            },
                        };
                        // 销售金额
                        lineColumn('salesAmountChart', dailyData);
                    }
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 拉取管控通知数据
        getControlNoticeData() {
            this.$axios
                .get(`/interfaceApi/sale/contract_life_cycle/contract_control/${this.contract_number}?zu_h_id=${this.zuHId}`)
                .then(res => {
                    this.controlNoticeData = res;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 打开工程进度
        projectProgress() {
            this.$toast({
                title: true,
                text: '工程进度',
                type: 'eject',
                width: '80%',
                height: '90%',
                t_url: 'salesBusiness/projectInfoManagement/projectInfo/projectProgress',
                viewPosition: 'view',
                extr: {
                    closeBtn: {},
                    engineering_id: this.basicContractInfo.engineering_id,
                },
            });
        },

        // 查看单体列表
        viewMonomer(eiid) {
            this.$toast({
                title: true,
                type: 'sideslip',
                width: '15.4rem',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'engineering_monomer_manage',
                        ViewCode: 'view',
                        QueryString: 'engineering_id=' + eiid,
                    },
                },
            });
        },
        // 合同档案
        contractFile(contract_number) {
            this.$toast({
                title: true,
                text: '合同档案信息',
                type: 'eject',
                width: '80%',
                height: '95%',
                t_url: 'salesBusiness/contractArchives/index',
                viewPosition: 'view',
                extr: {
                    closeBtn: {},
                    contract_number: contract_number,
                },
            });
        },
        // 数据管控
        dataGovernance(contract_number) {
            this.$toast({
                title: true,
                text: '管控数据详情',
                type: 'eject',
                width: '14rem',
                height: 'auto',
                t_url: 'contractControl/orderControlDetails',
                extr: {
                    control_instance_code: contract_number,
                    type: 2,
                },
            });
        },
        // 管控数据
        controlDetails(data) {
            if (data.business_type === 3) {
                this.$toast({
                    title: true,
                    text: '管控数据详情',
                    type: 'eject',
                    width: '14rem',
                    height: 'auto',
                    t_url: 'contractControl/orderControlDetails',
                    extr: {
                        control_instance_code: data.contract_number,
                        type: 2,
                    },
                });
            } else {
                this.$toast({
                    title: true,
                    text: '管控数据详情',
                    type: 'eject',
                    width: '14rem',
                    height: 'auto',
                    t_url: 'contractControl/orderControlDetails',
                    extr: {
                        control_instance_code: data.control_instance_code,
                        type: 1,
                    },
                });
            }
        },
    },
    filter: {},
    computed: {},
};
</script>

<style lang="stylus" scoped>
.contractLifeCycle
    height 100%
    padding 0 0.15rem 0.15rem
    .boxBorder
        border 1px solid #05A9F4
        background rgba(9, 18, 31, 0.3);
    .boxShadow
        padding 0.2rem
        box-shadow inset 1px 1px 20px 0px rgba(5, 169, 244, 0.5);
    .header
        overflow hidden
        font-size 0.18rem
        line-height 0.2rem
        height 0.2rem
        color rgba(255,255,255,0.8)
        padding-left 0.1rem
        margin-bottom 0.15rem
        background-image none
        border-left 0.06rem solid rgba(255,255,255,0.8)
    .noData
        position relative
        &:after
            content '暂无数据'
            font-size 0.5rem
            font-weight 600
            color rgba(255,255,255,0.3)
            position absolute
            top 50%
            left 50%
            transform translate(-50%, -50%)
    .lifeCycleHeader
        height 0.86rem
        background url(images/light.png) center bottom no-repeat
        .title
            font-size 0.2rem
            text-align center
            font-family Source Han Sans CN
            font-weight bold
            color rgba(255, 255, 255, 1)
            line-height 0.56rem
            background linear-gradient(0deg, rgba(108, 151, 216, 1) 0%, rgba(255, 255, 255, 1) 100%)
            -webkit-background-clip text
            -webkit-text-fill-color transparent
    .lifeCycleContent
        height calc(100% - 0.86rem)
        overflow-y auto
    .box_t
        height 12.44rem
        .box_t_l
            width 11.75rem
            height 100%
            .basicEngineeringInfo
                height 3.14rem
                margin-bottom 0.1rem
                >div
                    width calc(50% - 0.01rem)
                .engineeringInfo
                    height 100%
                    ul
                        li
                            display flex
                            line-height 0.3rem
                            p
                                flex 1
                                font-size 0.16rem
                                color rgba(255,255,255,0.8)
                                overflow hidden
                                text-overflow ellipsis
                                white-space nowrap
                    button
                        width 1.1rem
                        height 0.3rem
                        font-size 0.16rem
                        margin-right 0.2rem
                        color #C4FE72
                        cursor pointer
                        background rgba(196, 254, 114, 0.1)
                        border 1px solid rgba(196, 254, 114, 0.5)
                .el_right
                    height 100%
                .projectLocation
                    height 1.54rem
                    margin-bottom 0.02rem
                    position relative
                    .chart
                        width 2.4rem
                        height calc(100% - 0.35rem)
                    .map
                        position absolute
                        top 0.16rem
                        right 0.16rem
                        width 2.4rem
                        height 1.2rem
                        border 1px solid #0E3C6E
                .imageProgress
                    height calc(100% - 1.56rem)
                    .header
                        height 0.24rem
                        line-height 0.24rem
                        button
                            width 0.6rem
                            height 0.24rem
                            font-size 0.14rem
                            color #C4FE72
                            cursor pointer
                            background rgba(196, 254, 114, 0.1)
                            border 1px solid rgba(196, 254, 114, 0.5)
                    ul
                        overflow hidden
                        li
                            float left
                            width 0.9rem
                            height 0.8rem
                            margin-right 0.2rem
                            &:last-child
                                margin-right 0
                            iframe
                                width 100%
                                height 100%
            .projectProgress
                height 9.2rem
                .timeAxis
                    height 1.75rem
                    margin-bottom 0.02rem
                    .title
                        overflow hidden
                        .header
                            margin-top 0.05rem
                            margin-bottom 0
                        button
                            float left
                            width 60px
                            height 30px
                            margin-left 0.2rem
                            background rgba(85, 136, 241, 0.1)
                            border 1px solid #20B0FB
                            font-size 0.16rem
                            color #20B0FB
                            &.active
                                box-shadow inset 0px 1px 20px 0px #20B0FB
                    // .chart
                    //     height calc(100% - 0.5rem)
                    //     padding-top 0.23rem
                    ul
                        overflow hidden
                        display flex
                        margin-top 0.2rem
                        li
                            flex 1
                            margin-right 0.3rem
                            padding-left 0.1rem
                            &:last-child
                                margin-right 0
                            span,strong
                                display block
                            span
                                font-size 0.16rem
                                line-height 0.3rem
                                margin-bottom 0.1rem
                                color rgba(255,255,255,0.8)
                            strong
                                font-weight 400
                                font-size 0.36rem
                                line-height 0.3rem
                                font-family technology
                                color #FA8436
                .charts
                    height 3.7rem
                    >div
                        float left
                        width calc(50% - 0.01rem)
                        height 100%
                    .materialRequestTime
                        margin-right 0.02rem
                        .chart
                            height calc(100% - 0.35rem)
                    .constructionProgress
                        .chart
                            height calc(100% - 0.35rem)
                    &.amountMoney
                        margin-top 0.02rem
                        .salesAmount
                            margin-right 0.02rem
                            .chart
                                height calc(100% - 0.35rem)
                        .creditAmount
                            ul
                                height calc(100% - 0.35rem)
                                display flex
                                li
                                    height 100%
                                    flex 1
                                    text-align center
                                    &:nth-of-type(1)
                                        border-right 1px dashed rgba(255,255,255,0.5)
                                    >div
                                        width 1.7rem
                                        height 0.6rem
                                        margin-bottom 0.15rem
                                        display inline-block
                                        &.chart
                                            height 1.4rem
                                        &.totalAmount,
                                        &.amountUsed
                                            padding-top 0.05rem
                                            h5
                                                font-size 0.16rem
                                                lin-height 0.16rem
                                                color rgba(255,255,255,0.8)
                                                margin-bottom 0.05rem
                                            span
                                                display block
                                                line-height 0.24rem
                                                font-size 0.24rem
                                                color #20B0FB
                                                font-family technology
                                        &.totalAmount
                                            box-shadow inset 0px 1px 20px 0px #20B0FB
                                        &.amountUsed
                                            box-shadow inset 0px 1px 20px 0px #20B0FB
                                            span
                                                color #FA8436
        .box_t_r
            width 4.55rem
            height 100%
            .title
                height 0.56rem
                width 100%
                overflow hidden
                i,p
                    float left
                i
                    width 0.54rem
                    height 0.48rem
                    background url(images/icon_l.png) no-repeat
                    background-size 100%
                    position relative
                    z-z-index 9
                p
                    width calc(100% - 0.4rem)
                    height 0.4rem
                    margin 0.04rem 0 0.04rem -0.16rem
                    background rgba(80, 219, 84, 0.1)
                    text-align center
                    line-height 0.4rem
                    strong
                        font-size 0.18rem
                        color rgba(255,255,255,0.8)
                        vertical-align typeof
                        font-weight 400
                    span
                        font-family technology
                        font-size 0.36rem
                        color #50DB54
                        vertical-align top
            .amount
                height 3.9rem
                margin-bottom 0.02rem
                .chart
                    height calc(100% - 1.15rem)
                ul
                    overflow hidden
                    li
                        width calc((100% - 0.3rem) /3)
                        height 0.6rem
                        margin-right 0.15rem
                        text-align center
                        float left
                        padding 0.06rem
                        &:last-child
                            margin-right 0
                        h5
                            font-size 0.14rem
                            color #FFFFFF
                        span
                            font-size 0.24rem
                            line-height 0.3rem
                            color #20B0FB
                            font-family technology
                        &:nth-of-type(1)
                            box-shadow inset 0px 1px 20px 0px #20B0FB
                            span
                                color #05A9F4
                        &:nth-of-type(2)
                            box-shadow inset 0px 1px 20px 0px #FA8436
                            span
                                color #FA8436
                        &:nth-of-type(3)
                            box-shadow inset 0px 1px 20px 0px #78D563
                            span
                                color #78D563
            .price
                height 1.9rem
                margin-bottom 0.02rem
                .title
                    i
                        background url(images/icon_j.png) no-repeat
                        background-size 100%
                    p
                        background rgba(255, 178, 5, 0.1)
                        span
                            color #FA8436
                ul
                    margin-top 0.1rem
                    display flex
                    li
                        flex 1
                        width 1.3rem
                        text-align center
                        margin 0 0.3rem
                        h5,p
                            display inline-block
                        h5
                            font-size 0.16rem
                            color rgba(255,255,255,0.8)
                            margin-bottom 0.15rem
                        p
                            width 1.3rem
                            height 0.5rem
                            font-size 0.3rem
                            line-height 0.5rem
                            font-family technology
                        &:nth-of-type(1)
                            h5
                                &:before
                                    color #20B0FB
                            p
                                color #05A9F4
                                box-shadow inset 0px 1px 20px 0px #20B0FB
                        &:nth-of-type(2)
                            h5
                                &:before
                                    color #FC6B51
                            p
                                color #FC6B51
                                box-shadow inset 0px 1px 20px 0px #ED664F
                                span
                                    font-size 0.26rem
                                i
                                    font-size 0.24rem
                                    vertical-align top
                                    &.iconjiang
                                        display none
                            &.low
                                h5
                                    &:before
                                        color #78D563
                                p
                                    color #78D563
                                    box-shadow inset 0px 1px 20px 0px #78D563
                                    i
                                        font-size 0.24rem
                                        vertical-align top
                                        &.iconjiang-t
                                            display none
                                        &.iconjiang
                                            display inline-block
            .paragraph
                height 2.86rem
                margin-bottom 0.02rem
                .title
                    i
                        background url(images/icon_k.png) no-repeat
                        background-size 100%
                    p
                        background rgba(32, 176, 251, 0.1)
                        span
                            color #05A9F4
                ul
                    margin-top 0.2rem
                    li
                        display flex
                        div
                            flex 1
                            height 0.7rem
                            border 1px solid #20B0FB
                            background rgba(32, 176, 251, 0.1)
                            margin-right 0.1rem
                            margin-bottom 0.2rem
                            padding 0.1rem 0 0 0.2rem
                            &:last-child
                                margin-right 0
                            h5
                                font-size 0.16rem
                                line-height 0.16rem
                                color rgba(255,255,255,0.8)
                                margin-bottom 0.1rem
                            span
                                display block
                                font-size 0.24rem
                                line-height 0.24rem
                                color #05A9F4
                                font-family technology
                            &.receivable
                                border 1px solid #78D563
                                background rgba(120, 213, 99, 0.1)
                                span
                                    color #78D563
                            &.arrears
                                border 1px solid #FC6B51
                                background rgba(252, 107, 81, 0.1)
                                span
                                    color #FC6B51

            .consumption
                height 3.7rem
                ul
                    height calc(100% - 0.35rem)
                    overflow-y auto
                    li
                        overflow hidden
                        margin-bottom: 0.1rem
                        h5,p
                            float left
                            height 0.3rem
                            font-size: 0.14rem
                            line-height 0.3rem
                        h5
                            width 0.9rem
                            text-align: center
                            padding-right: 0.1rem
                        p
                            padding-left: 0.2rem
                            width calc(100% - 0.85rem)
                            margin-left: -0.05rem
                            color #C1D7FD
                            background: url(images/bg_r_5.png) no-repeat
                            background-size 100% 100%
                            span
                                width calc(100% - 1rem)
                                overflow hidden
                                text-overflow ellipsis
                                white-space: nowrap
                            strong
                                width 1rem
                                color #20B0FB
                                text-align right
                                font-weight 400
                                cursor pointer
                        &.earlyWarning
                            h5
                                background: url(images/bg_r_3.png) no-repeat
                                background-size 100% 100%
                                color #FDA655
                        &.control
                            h5
                                background: url(images/bg_r_4.png) no-repeat
                                background-size 100% 100%
                                color #FE5B5B

</style>
